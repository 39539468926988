.burger-btn {
  padding: 0;
  width: 28px;
  height: 24px;
  top: 0;
  right: 8px;
  background: none;
  border: none;

  cursor: pointer;
  position: relative;
  display: none;
  transition: color .3s ease;
}

.burger-btn::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 28px;
  height: 3px;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 10px 0 0 rgba(0, 0, 0, 1), 0 20px 0 0 rgba(0, 0, 0, 1);
}

@media screen and (max-width: 1023px) {
  .burger-btn {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .burger-btn {
    width: 23px;
    height: 23px;
  }

  .burger-btn::before {
    width: 22px;
    box-shadow: 0 9px 0 0 rgba(0, 0, 0, 1), 0 18px 0 0 rgba(0, 0, 0, 1);
  }
}

/* landing page */

.header_place_landing .burger-btn::before {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 10px 0 0 rgba(255, 255, 255, 1), 0 20px 0 0 rgba(255, 255, 255, 1);
}
