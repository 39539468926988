.techs {
  padding: 100px 0;
  background: var(--color-bg-secondary);
  color: var(--color-text-primary);
}

.techs__content {
  padding: 90px 0 100px;
  text-align: center;
}

.techs__subtitle {
  margin: 0 0 25px;
  padding: 0;
  font-size: var(--text-size-xxl);
  line-height: 1.15;
  font-weight: 400;
  letter-spacing: var(--letter-spacing-tight);
}

.techs__text {
  max-width: 460px;
  margin: 0 auto;
  padding: 0;
  font-size: var(--text-size-s);
  line-height: 1.5;
  letter-spacing: var(--letter-spacing-tight);
}

.techs__list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.techs__list-item {
  min-width: 90px;
  margin: 0;
  padding: 21px 10px;
  font-size: var(--text-size-s);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing-tight);
  text-align: center;
  background: var(--color-bg-tertiary);
  border-radius: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 1023px) {
  .techs {
    padding: 90px 0;
  }

  .techs__content {
    padding: 80px 0 83px;
  }

  .techs__subtitle {
    font-size: 50px;
  }

  .techs__list-item {
    min-width: 84px;
  }
}

@media screen and (max-width: 767px) {
  .techs {
    padding: 70px 0;
    background: var(--color-bg-primary);
  }

  .techs__content {
    padding: 60px 0 50px;
  }

  .techs__subtitle {
    font-size: var(--text-size-xxl);
  }

  .techs__text {
    max-width: 360px;
  }

  .techs__list {
    padding: 0 20px;
  }

  .techs__list-item {
    font-size: 12px;
  }
}

