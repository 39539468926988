.footer {
  background-color: #fafafa;
}

.footer__container {
  max-width: var(--max-content-width);
  width: calc(100% - 30px * 2);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer__title {
  margin: 0;
  padding: 0 0 20px;
  font-size: 13px;
  line-height: 1.25;
  color: var(--color-text-secondary);
  text-align: center;
  border-bottom: 1px solid var(--color-bg-tertiary);
}

.footer__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__copyright {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 1.25;
}

.footer__links {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer__link {
  font-size: 13px;
  line-height: 1.25;
  color: var(--color-text-primary);
  text-decoration: none;
  transition: color .3s ease;
}

.footer__link:hover {
  color: var(--color-link-hover);
}

@media screen and (max-width: 1023px) {
  .footer {
    background-color: var(--color-bg-primary);
  }
}

@media screen and (max-width: 767px) {
  .footer__container {
    gap: 28px;
    width: calc(100% - 10px * 2);
  }

  .footer__title {
    font-size: 12px;
  }

  .footer__content {
    flex-direction: column-reverse;
  }

  .footer__copyright {
    margin: 0 auto;
    font-size: 12px;
    color: var(--color-text-secondary);
  }

  .footer__links {
    margin: 0 0 30px;
    flex-direction: column;
    align-items: center;
    gap: 9px;
  }

  .footer__link {
    font-size: 12px;
  }
}
