.signin-btn {
  padding: 8px 20px;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  text-decoration: none;

  color: var(--color-text-primary);
  background: var(--color-accent-primary);
  border-radius: 3px;
  display: block;

  transition: opacity .3s ease;
}

.signin-btn:hover {
  opacity: .8;
}

@media screen and (max-width: 767px) {
  .signin-btn {
    padding: 5px 12px;
    font-size: 10px;
    line-height: 1.6;
  }
}
