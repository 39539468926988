.logo-link {
  transition: opacity .3s ease;
  display: flex;
  justify-content: center;
  align-content: center;
}

.logo-link:hover {
  opacity: .8;
}

.logo-link_place_form {
  margin: 0 0 38px;
  display: inline-block;
}

.logo-link__image_place_form {
  width: 38px;
  height: 38px;
}

@media screen and (max-width: 767px) {
  .logo-link_place_form {
    margin: 0 0 48px;
    text-align: center;
  }
}
