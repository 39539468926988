.page {
  background: var(--color-bg-primary);
  color: var(--color-text-primary);
  margin: 0;
  font-family: 'Inter', Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.page__container {
  min-width: var(--min-width);
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page__header {
  padding: 18px 0;
}

.page__content {
  flex-grow: 1;
  display: grid;
  grid-template-rows: min-content 1fr;
}

.page__footer {
  padding: 79px 0 20px;
}

@media screen and (max-width: 767px) {
  .page__footer {
    padding: 50px 0 20px;
  }
}
