.header {
  background: var(--color-bg-primary);
}

.header__container {
  max-width: var(--max-content-width);
  width: calc(100% - 30px * 2);
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .header__container {
    width: calc(100% - 14px * 2);
  }
}

/* landing page */

.header_place_landing {
  background: var(--color-bg-promo-only);
}
