.promo {
  padding: 75px 0;
  background: var(--color-bg-promo-only);
  color: var(--color-text-tertiary);
}

.promo__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 48px;
}

.promo__main-content {
  display: flex;
  flex-direction: column;
}

.promo__title {
  max-width: 640px;
  margin: 0 0 36px;
  font-size: var(--text-size-xxl);
  line-height: 1.15;
  font-weight: 400;
}

.promo__text {
  max-width: 310px;
  margin: 0 0 10px;
  font-size: var(--text-size-xs);
  line-height: 1.25;
  opacity: .5;
}

.promo__text:nth-of-type(2) {
  margin: 0 0 146px;
}

.promo__link {
  width: 0;
  min-width: 129px;
  padding: 10px 19px;
  color: var(--color-text-tertiary);
  font-size: var(--text-size-xxs);
  line-height: 1.25;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, .6);
  border-radius: 3px;
  
  transition: color .5s ease, background-color .5s ease;

  display: inline-block;
  box-sizing: border-box;
}

.promo__link:hover {
  background-color: var(--color-bg-primary);
  color: var(--color-link-hover-promo-only);
}

.promo__illustration {
  width: 310px;
  height: 304px;
}

@media screen and (max-width: 1023px) {
  .promo__container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .promo__main-content {
    align-items: center;
    text-align: center;
  }

  .promo__title {
    line-height: 1.25;
    margin: 0 0 22px;
  }

  .promo__text:nth-of-type(2) {
    margin: 0 0 90px;
  }
}  

@media screen and (max-width: 767px) {
  .promo {
    padding: 70px 0 30px;
  }

  .promo__title {
    letter-spacing: var(--letter-spacing-tight);
  }

  .promo__text {
    max-width: 260px;
  }

  .promo__text:nth-of-type(2) {
    margin: 0 0 80px;
  }

  .promo__illustration {
    width: 210px;
    height: 206px;
  }
}
