.movies-card {
  display: grid;
  grid-template-rows: 201px 1fr;
  background-color: var(--color-bg-primary);
  border-radius: 6px;

  position: relative;
}

.movies-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;

  cursor: pointer;
}

.movies-card__desc {
  width: calc(100% - 14px * 2);
  margin: 0 auto;
  padding: 14px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
  
.movies-card__title {
  max-width: 250px;
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.25;
}

.movies-card__duration {
  min-width: 53px;
  margin: 0;
  padding: 4px 8px;
  font-size: 11px;
  line-height: 0.9;
  color: var(--color-text-secondary);
  background: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
}

.movies-card__btn {
  height: 21px;
  padding: 6px 10px;
  font-size: 10px;
  line-height: 0.9;
  color: var(--color-text-primary);
  background-color: rgba(249, 249, 249, 1);;
  border: none;
  border-radius: 30px;

  position: absolute;
  top: 14px;
  right: 14px;

  cursor: pointer;
  opacity: 0;
  transition: opacity .3s, color .3s, background-color .3s ease;
}

.movies-card__btn_use_delete {
  width: 21px;
  background-image: url('../../images/cross-icon.svg');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 50%;
}

.movies-card:hover .movies-card__btn {
  opacity: 1;
}

.movies-card__btn:hover {
  color: rgba(0, 0, 0, .8);
  background-color: rgba(249, 249, 249, .8);
}
.movies-card__btn:focus {
  opacity: 1;
}

.movies-card__btn_active {
  padding: 0 6px;
  color: var(--color-text-tertiary);
  background-color: var(--color-accent-secondary);
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .movies-card {
    grid-template-rows: 190px 1fr;
  }

  .movies-card__desc {
    width: 100%;
    padding: 26px 0 14px;
  }
}

@media screen and (max-width: 767px) {
  .movies-card {
    grid-template-rows: 168px 1fr;
  }

  .movies-card__desc {
    padding: 16px 0 14px;
  }

  .movies-card__title {
    max-width: 240px;
    font-size: 12px;
  }

  .movies-card__btn {
    top: 10px;
    right: 10px;
    opacity: 1;
  }
}
