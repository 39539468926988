.mobile-navigation {
  height: 100%;
}

.mobile-navigation__list {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;

  overflow-y: auto;
}
.mobile-navigation__list-item:last-of-type {
  margin-top: auto;
}

.mobile-navigation__link {
  padding: 4px 0;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-text-primary);
}

.mobile-navigation__link_active {
  border-bottom: 2px solid var(--color-text-primary);
}

@media screen and (max-width: 767px) {
  .mobile-navigation__list {
    gap: 25px;
  }

  .mobile-navigation__link {
    padding: 7px 0;
  }
}
