.about-me {
  padding: 110px 0 80px;
}

.about-me__content {
  padding: 65px 0;

  display: grid;
  grid-template-columns: 1fr 270px;
  gap: 40px;
}

.about-me__image {
  max-width: 270px;
  min-height: 327px;
  object-fit: cover;

  border-radius: 10px;
}

.about-me__student {
  grid-column-start: 1;
  grid-row-start: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-me__subtitle {
  margin: 0 0 19px;
  padding: 0;
  font-size: var(--text-size-xxl);
  line-height: 1.15;
  font-weight: 400;
  letter-spacing: var(--letter-spacing-tight);
}

.about-me__headline {
  margin: 0 0 26px;
  padding: 0;
  font-size: var(--text-size-m);
  line-height: 1.1;
  font-weight: 500;
}

.about-me__text {
  margin: 0 0 26px;
  padding: 0;
  max-width: 600px;
  font-size: var(--text-size-s);
  line-height: 1.6;
}

.about-me__links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.about-me__link {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--color-text-primary);
  text-decoration: none;
  transition: color .3s ease;
}

.about-me__link:hover {
  color: var(--color-link-hover);
}

@media screen and (max-width: 1023px) {
  .about-me {
    padding: 90px 0;
  }

  .about-me__content {
    grid-template-columns: 1fr 255px;
  }

  .about-me__image {
    max-width: 255px;
    min-height: 307px
  }

  .about-me__subtitle {
    margin: 0 0 15px;
    line-height: 1;
  }

  .about-me__headline {
    margin: 0 0 20px;
    font-size: var(--text-size-s);
    line-height: 1.5;
  }

  .about-me__text {
    line-height: 1.5;
  }
}

@media screen and (max-width: 767px) {
  .about-me {
    padding: 70px 0;
  }

  .about-me__content {
    padding: 60px 0;
    grid-template-columns: 1fr;
  }

  .about-me__student {
    grid-row-start: 2;
  }

  .about-me__image {
    margin: 0 auto;
    max-width: 292px;
    min-height: 352px
  }

  .about-me__subtitle {
    margin: 0 0 20px;
    line-height: 1.2;
  }

  .about-me__text {
    margin: 0 0 40px;
  }
}
