.section__title {
  width: 100%;
  margin: 0;
  padding: 0 0 22px;
  font-size: 22px;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: var(--letter-spacing-tight);
  border-bottom: 1px solid var(--color-text-primary);
}

@media screen and (max-width: 767px) {
  .section__title {
    font-size: 18px;
    padding: 0 0 28px;
  }
}
