.filter-checkbox {
  padding: 10px 0 10px 29px;
  border-left: 1px solid #cbcbcb;
}

.filter-checkbox__label {
  font-size: 13px;
  line-height: 1.4;
  padding-left: calc(36px + 14px); /* slider width plus desired gap */

  display: block;
  position: relative;
  cursor: pointer;
}

.filter-checkbox__input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.filter-checkbox__input:focus-visible + .filter-checkbox__slider {
  outline: auto;
}

.filter-checkbox__input:checked + .filter-checkbox__slider {
  background-color: var(--color-accent-primary);
}

.filter-checkbox__input:checked + .filter-checkbox__slider:after {
  left: calc(36px - 16px - 2px); /* slider width minus toggle control width minus toggle gutter */
  background-color: var(--color-bg-secondary);
}

.filter-checkbox__slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 36px; /* slider width */
  height: 20px;
  border-radius: 20px;
  background-color: #ebebeb;

  transition: background-color .3s ease, opacity .3s ease;
}

.filter-checkbox__slider:hover {
  opacity: .8;
}

.filter-checkbox__slider:after {
  content: "";
  position: absolute;
  top: 2px; /* toggle gutter */
  left: 2px; /* toggle gutter */
  width: 16px; /* toggle control width */
  height: 16px;
  border-radius: 50px;
  background: var(--color-bg-primary);

  transition: background-color .3s ease, left .3s ease;
}

@media screen and (max-width: 1023px) {
  .filter-checkbox {
    padding: 10px 0 10px 19px;
  }
}

@media screen and (max-width: 767px) {
  .filter-checkbox {
    padding: 0;
    border-left: none;
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
  }

  .filter-checkbox__label {
    font-size: 11px;
    line-height: 1.65;
  }
}
