.navigation__items {
  margin: 0;
	padding: 0;
	list-style: none;

	display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
	gap: 10px 50px;
}

.navigation__links-container {
  display: flex;
  gap: 20px;
}

.navigation__link {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
  text-decoration: none;
  color: var(--color-text-primary);
  
  display: block;
  transition: color .3s ease;
}

.navigation__link:hover {
  color: rgba(0, 0, 0, .6);
}

.navigation__link_active {
  font-weight: 500;
}

@media screen and (max-width: 1023px) {
  .navigation {
    display: none;
  }
}

/* landing page */

.header_place_landing .navigation__link {
  color: var(--color-text-tertiary);
}

.header_place_landing .navigation__link:hover {
  color: rgba(255, 255, 255, .6);
}
