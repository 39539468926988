.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, .3);
  z-index: 1;

  display: flex;
  visibility: hidden;
  opacity: 0;

  transition: visibility .5s, opacity .5s ease;
}

.side-menu_opened {
  visibility: visible;
  opacity: 1;
}

.side-menu__container {
  position: fixed;
  top: 0;
  right: -520px;

  width: 520px;
  height: 100vh;
  padding: 160px 90px 90px;
  box-sizing: border-box;
  background: var(--color-bg-primary);

  transition: right .5s ease;
}

.side-menu__container_opened {
  right: 0;
}

@media screen and (max-width: 767px) {
  .side-menu__container {
    width: 100%;
    padding: 145px 10px 46px;
  }
}

@media screen and (max-height: 569px) {
  .side-menu__container {
    padding: 96px 10px 46px;
  }
}
